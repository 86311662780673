import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';
import { DeviceTypes } from '@shared/enums';

export interface DeviceResponse {
  deviceId: number;
  name: string;
  deviceType: DeviceTypes;
  statusType: number;
  deviceTrackings: DeviceTrackingsResponse;
  connectionId?: null;
}

export interface DeviceSignalRResponse {
  deviceId: number;
  name: string;
  deviceType: DeviceTypes;
  statusType: number;
  deviceTrackings: DeviceTrackingResponse[];
  connectionId?: null;
}

export interface DevicesResponse {
  $values: DeviceResponse[];
}

export interface DeviceTrackingsResponse {
  $values: DeviceTrackingResponse[];
}

export interface DeviceTrackingResponse {
  deviceTrackingId: number;
  deviceId: number;
  applicationVersion: string;
  make: string;
  model: string;
  serialNumber: string;
  createdOn: Date;
}

export interface DeviceTracking {
  DeviceTrackingId: number;
  DeviceId: number;
  ApplicationVersion: string;
  Make: string;
  Model: string;
  SerialNumber: string;
  CreatedOn: Date;
}

export interface Device {
  DeviceId: number;
  Name: string;
  DeviceType: DeviceTypes;
  StatusType: number;
  DeviceTrackings: DeviceTracking[];
  ConnectionId?: string | null;
}

export interface LegacyDeviceResponse {
  Device: {
    DeviceId: number;
    Name: string;
    LocationId: number;
    MerchantId?: number | null;
    DeviceType: DeviceTypes;
    StatusType: number;
  };
}

export interface LegacyDeviceManageRequest {
  DeviceId?: number | null;
  Name: string;
  DisplayCode: string | null;
  LocationId?: number;
  MerchantId?: number;
  DeviceType?: DeviceTypes;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService extends BaseApiService {
  private readonly deviceEndpoint = '/v2/Devices';
  private readonly deviceLegacyEndpoint = '/Device';

  findAll() {
    return this.get<DevicesResponse>(`${this.deviceEndpoint}`).pipe(
      catchError((err) => {
        console.error(err);
        const emptyDevicesResponse: DevicesResponse = { $values: [] };
        return of(emptyDevicesResponse);
      })
    );
  }

  findUnique(deviceId: number) {
    return this.get<DeviceResponse>(`${this.deviceEndpoint}/${deviceId}`).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  create(newDevice: LegacyDeviceManageRequest) {
    return this.post<LegacyDeviceResponse>(
      `${this.deviceLegacyEndpoint}/Save`,
      {
        ...newDevice,
      },
      true
    ).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  update(updatedDevice: LegacyDeviceManageRequest) {
    // This shouldn't be a POST, but legacy is dumb and uses a POST for an update
    return this.post<LegacyDeviceResponse>(
      `${this.deviceLegacyEndpoint}/Save`,
      {
        ...updatedDevice,
      },
      true
    ).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  triggerBug(deviceId: number) {
    return this.post(`${this.deviceEndpoint}/${deviceId}/bug`, null);
  }
}
